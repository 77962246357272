import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import SubscribeDialogue from "./SubscribeDialogue";
import MenuIcon from "@mui/icons-material/Menu";

const pagesRoutes = [
  { label: "Products", route: "/product" },
  // { label: "Testimonials", route: "/testimonials" },
  { label: "Data Security", route: "/security" },
  { label: "About Us", route: "/aboutUs" },
];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [shouldShowShadow, setShouldShowShadow] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const handleRedirectLogin = () => {
    window.location.href = 'https://app.clinicalpad.com/login';  
  };

  const handleRedirectSignup = () => {
    window.location.href = 'https://app.clinicalpad.com/hospital'; 
  };
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setShouldShowShadow(true);
      } else {
        setShouldShowShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      style={{
          backgroundColor: "#F3F6F6",
        padding: 8,
        position: "sticky",
        top: 0,
        zIndex: "999",
        boxShadow: shouldShowShadow ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            href="#app-bar-with-responsive-menu"
            sx={{
              fontWeight: 700,
              textDecoration: "none",
              fontFamily: "GilroyMedium",
              marginLeft: { xs: "20", md: "40px" },
              mt: { xs: 1, md: 0 },
                display: 'flex',
            }}
          >
              <img src={"images/ClinicalPadLogo.svg"} width="160"
                   height="70px" alt={"logo"}/>
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              gap: 4,
            }}
          >
            {pagesRoutes.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  color: "#1C1C1C",
                  display: "block",
                  textTransform: "none",
                  fontFamily: "GilroyMedium",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "14px",
                  textAlign: "left",
                }}
                component={Link}
                to={page.route}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0,gap:'8px', display: { xs: "none", md: "flex" } }}>
            <Button
              variant="contained"
              style={{
                borderRadius: "100px",
                padding: "11px 32px 11px 32px",
                  color: '#fff',
                  background: 'linear-gradient(to right, #354bb6, #495cc4, #5d6fd2, #7180e1, #8792f0, #9ba2fe)',
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 700,
                boxShadow: "none",
                fontFamily: "PlusJakartaSans",
              }}
              onClick={handleRedirectLogin}
            >
              Login
            </Button>
            <Button
              variant="contained"
              style={{
                borderRadius: "100px",
                padding: "11px 32px 11px 32px",
                backgroundColor: "#fff",
                border:'2px solid #171819',
                color: "#171819",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 700,
                boxShadow: "none",
                fontFamily: "PlusJakartaSans",
              }}
              onClick={handleRedirectSignup}
            >
              Try Now
            </Button>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              style={{
                borderRadius: "100px",
                padding: "9px 18px 9px 18px",
                backgroundColor: "#171819",
                color: "#fff",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 700,
                boxShadow: "none",
                fontFamily: "PlusJakartaSans",
                marginRight: "4px",
              }}
             
              onClick={handleRedirectLogin}
            >
              Login
            </Button>
            <Button
              onClick={handleOpenNavMenu}
              variant="contained"
              style={{
                borderRadius: "100px",
                // padding: "2px 8px",
                minWidth: "22px",
                backgroundColor: "#171819",
                color: "#fff",
                textTransform: "none",
                fontSize: "18px",
                lineHeight: "1px",
                fontWeight: 700,
                boxShadow: "none",
                fontFamily: "PlusJakartaSans",
              }}
            >
              <MenuIcon sx={{ fontSize: "16px" }} />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pagesRoutes.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#1C1C1C",
                      display: "block",
                      textTransform: "none",
                      textDecoration: "none",
                      fontFamily: "GilroyMedium",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "14px",
                    }}
                    component={Link}
                    to={page.route}
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>

      <SubscribeDialogue open={openModal} onClose={() => setOpenModal(false)} />
    </AppBar>
  );
}
export default Header;
