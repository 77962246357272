import React from "react";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";

function ImageWithText(props) {
  const {
    imageUrl,
    heading,
    paragraph,
    imagePosition,
    redirectToIndividual,
    hideButton,
    gridImage,
    gridText,
    buttonText,
  } = props;

  const renderImage = () => {
    return (
      <img
        src={imageUrl}
        alt={heading}
        style={{
          // width: "-webkit-fill-available",
          // height: "-webkit-fill-available",
          width: "100%",
        }}
      />
    );
  };

  const renderText = () => {
    return (
      <>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
          }}
        >
          {heading}
        </Typography>

        {/* Paragraph */}
        <Typography
          variant="body1"
          paragraph
          style={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: "16PX",
            lineHeight: "21px",
          }}
        >
          {paragraph}
        </Typography>

        {/* Explore More button */}
        {hideButton ? null : (
          <Button
            variant="contained"
            style={{
              borderRadius: "100px",
              padding: "11px 32px",
              color: '#fff',
              background: 'linear-gradient(to right, #354bb6, #495cc4, #5d6fd2, #7180e1, #8792f0, #9ba2fe)',
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
            }}
            onClick={redirectToIndividual}
          >
            {buttonText || "Explore More"}
          </Button>
        )}
      </>
    );
  };

  return (
    <Box
      className="container"
      sx={{
        paddingBottom: { xs: "60px", md: "140px" },
        paddingTop: { xs: "60px" },
      }}
    >
      <Grid
        key={heading}
        container
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 6, md: 3 }}
      >
        {/* Right side content */}
        {imagePosition === "left" && (
          <>
            <Grid item xs={12} md={gridImage}>
              {renderImage()}
            </Grid>
            <Grid item xs={12} md={gridText}>
              {renderText()}
            </Grid>
          </>
        )}

        {/* Left side content */}
        {imagePosition === "right" && (
          <>
            <Grid item xs={12} md={gridText}>
              {renderText()}
            </Grid>
            <Grid item xs={12} md={gridImage}>
              {renderImage()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default ImageWithText;
