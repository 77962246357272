import { Typography, Hidden, Box } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import privacyPolicy from "../utils/privacyPolicy.json";
import TermsCondition from "../component/TermsCondition";

const PrivacyPolicies = () => {
  return (
      <Box style={{backgroundColor: "#F3F6F6"}}>
      <Header />

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: { xs: "30px", md: "40px" },
          pb: { xs: "40px", md: "80px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/testimonialHeader1.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { md: 5 },
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
      </Box>

      <TermsCondition data={privacyPolicy} />

      <Footer />
    </Box>
  );
};

export default PrivacyPolicies;
