import { Typography, Box } from "@mui/material";
import CarouselComponent from "./CarouselComponent";
import Steppers from "./Steppers";
import { useState } from "react";
import ProductCarousel from "./ProductCarousel";

const cardsData = [
  {
    imageUrl: "images/product/Generate.png",
    title: "Generate",
    description: "Input only key words and let AI craft your document's content.",
  },
  {
    imageUrl: "images/product/Select-Template.png",
    title: "Select Template",
    description: "Choose from a range of templates that best suits your needs.",
  },
  {
    imageUrl: "images/product/Customize.png",
    title: "Customise",
    description:
      "Personalise the template and refine the AI-generated content to your preference.",
  },
];

const ProductLetters = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleStepChange = (step) => {
    // setActiveStep(step);
    // setActiveSlide(step); // Sync active slide with active step
  };

  const handleSlideChange = (index) => {
    setActiveSlide(index);
    setActiveStep(index); // Sync active step with active slide
  };

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
          backgroundColor: '#dce5e5',
          flexDirection: "column",
        pt: { xs: "60px", md: "80px" },
        pb: { xs: "60px", md: "80px" },
      }}
    >
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 600,
          fontSize: 22,
          lineHeight: "32px",
        }}
      >
        How it Works
      </Typography>
      <Typography
        sx={{
          marginTop: "24px",
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
          marginBottom: "24px",
        }}
      >
        Effortlessly Craft Stunning Documents:
      </Typography>

      {/* <Steppers activeStep={activeStep} handleStepChange={handleStepChange} /> */}
      <ProductCarousel
        md={4}
        cardsData={cardsData}
        activeSlide={activeSlide}
        setActiveSlide={handleSlideChange}
      />
    </Box>
  );
};

export default ProductLetters;
