import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";


// TODO ADD SECURITY IMAGE IN ex: BENEFITS SECTION

const cardData = [
  {
    imageUrl: "images/home//Savings.png",
    title: "Savings",
    description:
      "Save up to 90% in costs generating clinical documents.",
  },
  {
    imageUrl: "images/home/Wait-time.png",
    title: "Wait time",
    description:
      "Reducing wait times for a completed clinical document by 48 hours.",
  },
  {
    imageUrl: "images/home/Security.png",
    title: "Security",
    description:
      "Adhering to stringent security measures, ensuring user and patient safety at all times",
  },
];
const BenefitsSection = () => {
  return (
    <Box
      className="container"
      sx={{
        // paddingLeft: { xs: "24px", md: "100px" },
        // paddingRight: { xs: "24px", md: "100px" },
        paddingTop: { xs: "80px" },
        paddingBottom: { xs: "80px" },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          marginBottom: "40px",
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
        }}
      >
        It’s more than just a medical transcription platform
      </Typography>
      <Grid container spacing={{ xs: 6, md: 4 }}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{boxShadow: "none", backgroundColor: "#F3F6F6"}}>
              <CardMedia
                component="img"
                image={card.imageUrl}
                alt={card.title}
              />
            </Card>
            <Box>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  marginTop: "21px",
                  fontFamily: "GilroyMedium",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "26px" },
                  lineHeight: { xs: 1.2 },
                }}
              >
                {card.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: { xs: 0, md: "21px" },
                  fontWeight: 500,
                  fontSize: "16px",
                  fontHeight: "21px",
                }}
              >
                {card.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BenefitsSection;
