// In Home.js
import React from "react";
import Header from "../component/Header";
import FirstSection from "../component/homepage/FirstSection";
import HomeImageWithText from "../component/HomeImageWithText";
import Box from "@mui/material/Box";
import BenefitsSection from "../component/homepage/BenefitsSection";
import HighlightSection from "../component/homepage/HighlightSection";
import JoinProduct from "../component/JoinProduct";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Home = () => {
  const redirectToIndividual = () => {};
  const isSmallScreen = useMediaQuery("(max-width: 769px)");

  return (
      <Box style={{backgroundColor: "#f3f6f6"}}>
      <Header />
      <FirstSection />
      <img
        src={"images/home/home1.jpg"}
        alt={"section2_image"}
        style={{
          // width: "-webkit-fill-available",
          // height: "-webkit-fill-available",
          width: "100%",
          height: "100%"
        }}
      />
      <Box
        sx={{
          backgroundColor: "#dce5e5",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/homeXS2.png"
              : "images/home/home2.png"
          }
          heading="Ease of Note Taking"
          paragraph="ClinicalPad features an intuitive, user-friendly online document creator designed to accommodate various note-taking preferences. Users can type, dictate, record consultations, upload images of handwritten notes, and attach
            supporting images or files. This versatile functionality caters to diverse
            documentation needs, making it easier for healthcare professionals to capture
            and manage information efficiently."
          imagePosition="right"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#f3f6f6",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/home3_2.png"
              : "images/home/home3_2.png"
          }
          heading="Clinical Templates"
          paragraph="Users can now choose from over 50 professionally curated clinical templates
to generate a variety of clinical documents. Additionally, users can create
their very own templates empowering users to create personalised clinical documents tailored to meet their needs."
          imagePosition="left"
          backgroundColor="#F3F6F6"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#dce5e5",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/home4_2.png"
              : "images/home/home4_2.png"
          }
          heading="Medical History"
          paragraph="Each time a clinical document is created for a patient in ClinicalPad, their medical history is automatically and accurately updated with the latest
information. ClinicalPad captures and organizes this data in a clear, easy-toread format, eliminating the need for manual updates and significantly saving
time for healthcare providers."
          imagePosition="right"
          backgroundColor="#dce5e5"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#f3f6f6",
        }}
      >
        <BenefitsSection />
      </Box>
          <Box
              sx={{
                  backgroundColor: "#dce5e5",
              }}
          >
              <JoinProduct/>
          </Box>

      <ContactUs />
      <Footer />
    </Box>
  );
};

export default Home;
