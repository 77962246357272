import React from "react";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

function HomeImageWithText(props) {
  const {
    imageUrl,
    heading,
    paragraph,
    imagePosition,
    backgroundColor,
    hideButton,
    buttonText,
  } = props;

  const renderImage = () => {
    return (
      <img
        src={imageUrl}
        alt={heading}
        style={{
          // width: "-webkit-fill-available",
          // height: "-webkit-fill-available",
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  const renderText = () => {
    return (
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
          }}
        >
          {heading}
        </Typography>

        {/* Paragraph */}
        <Typography
          variant="body1"
          paragraph
          style={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: "16PX",
            lineHeight: "21px",
          }}
        >
          {paragraph}
        </Typography>

        {/* Explore More button */}
        {hideButton ? null : (
          <Button
            variant="contained"
            sx={{
              borderRadius: "100px",
                padding: "11px 32px", color: '#fff',
                background: 'linear-gradient(to right, #354bb6, #495cc4, #5d6fd2, #7180e1, #8792f0, #9ba2fe)',

                textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
              mb: { xs: "30px", md: 0 },
            }}
            component={Link}
            to="/product"
          >
            {buttonText || "Explore More"}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box className="container">
      <Grid
        key={heading}
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingBottom: { xs: "20px", md: "70px" },
          paddingTop: { xs: "60px", md: 0 },
          // px: { xs: "24px", md: "300px" },
        }}
        spacing={{ xs: 0, md: 3 }}
      >
        {/* Right side content */}
        {imagePosition === "left" && (
          <>
            <Grid item xs={12} md={8} className="containerFluid">
              {renderImage()}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderText()}
            </Grid>
          </>
        )}

        {/* Left side content */}
        {imagePosition === "right" && (
          <>
            <Grid item xs={12} md={4}>
              {renderText()}
            </Grid>
            <Grid item xs={12} md={8} className="containerFluid">
              {renderImage()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default HomeImageWithText;
