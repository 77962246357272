// In AboutUs.js
import React from "react";
import Header from "../component/Header";
import ImageWithText from "../component/ImageWithText";
import Box from "@mui/material/Box";
import Footer from "../component/Footer";
import SecondSection from "../component/aboutPage/SecondSection";
import Support from "../component/Support";
import MeetTeam from "../component/MeetTeam";
import { supportQuestions } from "../utils/supportQuestions";

const AboutUs = () => {
  return (
      <Box style={{backgroundColor: "#dce5e5"}}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#dce5e5",
        }}
      >
        <ImageWithText
          imageUrl="images/aboutUs/about1.png"
          heading="About Us"
          paragraph="Welcome to ClinicalPad, where tradition meets innovation in the realm of medical documentation. Initially a traditional medical transcription company, ClinicalPad has evolved dramatically to address the pressing needs of modern healthcare professionals. Today, it stands as a pioneer in medical documentation, integrating the power of Artificial Intelligence (AI) and Machine Learning (ML) to enhance the accuracy and efficiency of clinical records."
          imagePosition="right"
          hideButton={true}
          gridImage={6}
          gridText={6}
          redirectToIndividual={() => {}}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#f3f6f6",
        }}
      >
        <ImageWithText
          imageUrl="images/aboutUs/about2.png"
          heading="It All Started in 2018"
          paragraph="Our journey began with a simple yet profound vision: to transform the cumbersome, often inefficient world of medical transcription. Recognising the challenges faced by clinicians with outdated transcription services that were not only costly but also time-consuming, ClinicalPad was reimagined in 2022 by a team of visionary entrepreneurs and clinicians. Their goal was to develop a tool that not only met but exceeded the rigorous demands of healthcare documentation."
          imagePosition="left"
          hideButton={true}
          gridImage={6}
          gridText={6}
          redirectToIndividual={() => {}}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#dce5e5",
        }}
      >
        <ImageWithText
          imageUrl="images/aboutUs/about3.png"
          heading="We are on a Mission"
          paragraph="ClinicalPad is more than just a web-based writing tool; it is a testament to the dedication of our founders who designed the platform specifically for clinicians. This user-friendly platform drastically reduces the time required to create clinical documents—starting with comprehensive clinical letters, ClinicalPad will be expanding its template selection to include a variety of clinical documents including SOAP notes, H&P notes, discharge summaries, and more. Each feature, from voice-to-text dictation to customisable templates and collaborative tools, is crafted to streamline the workflow in medical practices."
          imagePosition="right"
          hideButton={true}
          gridImage={6}
          gridText={6}
          redirectToIndividual={() => {}}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#F3F6F6",
        }}
      >
        <ImageWithText
          imageUrl="images/aboutUs/about4.png"
          heading="Join Our Team"
          paragraph="At ClinicalPad, data security isn't an afterthought, it's our knight in shining armour. Your information rests easy in our secure digital vault."
          imagePosition="left"
          hideButton={false}
          gridImage={6}
          gridText={6}
          redirectToIndividual={() =>
            window.open("https://www.linkedin.com/company/clinicalpad/")
          }
          buttonText="View Open Roles"
        />
      </Box>

          <Box sx={{backgroundColor: '#dce5e5'}}>
              <MeetTeam/>
          </Box>
          <Box sx={{backgroundColor: '#F3F6F6', paddingBottom: '10px'}}>
              <Support supportQuestions={supportQuestions}/>
          </Box>
      <Footer />
    </Box>
  );
};

export default AboutUs;
